// FOR AUTH
export const LOGIN = 'login'
export const CHECK_AUTH = 'checkAuth'
export const FETCH_ME = 'fetchMe'
export const LOGOUT = 'logout'
export const REQUEST_RESET_PASSWORD = 'requestResetPassword'
export const FORGOT_PASSWORD = 'forgotPassword'
export const RESET_PASSWORD = 'resetPassword'

// FOR DATA POLICIES
export const FETCH_DATA_POLICIES = 'fetchDataPolicies'
export const CHECK_DATA_POLICIES = 'checkDataPolicies'
export const ACCEPT_DATA_POLICY = 'acceptDataPolicy'
export const WITHDRAW_DATA_POLICY = 'withdrawDataPolicy'

// FOR CUSTOM THEMES
export const FETCH_CUSTOM_THEME = 'fetchCustomTheme'
export const CHANGE_CUSTOM_THEME = 'changeCustomTheme'
export const RESET_CUSTOM_THEME = 'resetCustomTheme'

// FOR ORGANISATIONS
export const SELECT_ORGANISATION = 'selectOrganisation'
export const FETCH_ORGANISATIONS = 'fetchOrganisations'
export const FETCH_ORGANISATIONS_LITE = 'fetchOrganisationsLite'
export const FETCH_ORGANISATION_BY_ID = 'fetchOrganisationById'
export const FETCH_ALL_ORG_USERS = 'fetchAllOrgUsers'
export const FETCH_UNASSIGNED_BY_ORG = 'fetchUnassignedByOrg'
export const FETCH_USERS_BY_ORG = 'fetchUsersByOrg'
export const FETCH_PLAYERS_BY_ORG = 'fetchPlayersByOrg'
export const FETCH_TRAINERS_BY_ORG = 'fetchTrainersByOrg'
export const FETCH_ADMINS_BY_ORG = 'fetchAdminsByOrg'
export const ADD_ORGANISATION = 'addOrganisation'
export const ADD_ORGANISATION_WITH_LICENSE = 'addOrganisationWithLicense'
export const UPDATE_ORGANISATION = 'updateOrganization'
export const DELETE_ORGANISATION = 'deleteOrganisation'
export const UPDATE_ORG_ADMIN = 'updateOrgAdmin'
export const ADD_USER_TO_ORG = 'addUserToOrg'

// FOR RANKING
export const FETCH_PLAYER_SKILLGAME_RANKING = 'fetchPlayerSkillgameRanking'
export const FETCH_COUNTRY_SKILLGAME_RANKING = 'fetchCountrySkillgameRanking'
export const FETCH_TEAM_STENGTH_INDEX_RANKING = 'fetchTeamStrengthIndexRanking'
export const FETCH_TEAM_SKILLGAME_RANKING = 'fetchTeamSkillgameRanking'
export const FETCH_PLAYER_BEST_RANKS = 'fetchPlayerBestRanks'
export const FETCH_TEAM_BEST_RANKS = 'fetchTeamBestRanks'
export const FETCH_COUNTRIES = 'fetchCountries'
export const FETCH_POSITIONS = 'fetchPositions'

// FOR SKILLGAMES
export const FETCH_SKILLGAME = 'fetchSkillgame'
export const FETCH_SKILLGAMES = 'fetchSkillgames'
export const FETCH_TEAM_SKILLGAMES = 'fetchTeamSkillgames'

// FOR RESULTS
export const FETCH_RESULTS = 'fetchResults'
export const FETCH_PLAYER_SKILLGAME_RESULTS = 'fetchPlayerSkillgameResults'
export const FETCH_BEST_RESULTS = 'fetchBestResults'
export const FETCH_ALL_RESULTS = 'fetchAllResults'

// FOR TEAMS
export const SELECT_TEAM = 'selectTeam'
export const FETCH_TEAM_BY_ID = 'fetchTeamById'
export const FETCH_COMPLETE_TEAM_BY_ID = 'fetchCompleteTeamById'
export const FETCH_TEAMS_BY_ORG = 'fetchTeamsByOrg'
export const FETCH_AND_STORE_TEAMS_BY_ORG = 'fetchAndStoreTeamsByOrg'
export const FETCH_ALL_TEAM_USERS = 'fetchAllTeamUsers'
export const FETCH_PLAYERS_BY_TEAM = 'fetchPlayersByTeam'
export const FETCH_ALL_PLAYERS_BY_TEAM = 'fetchAllPlayersByTeam'
export const FETCH_TRAINERS_BY_TEAM = 'fetchTrainersByTeam'
export const FETCH_TRAINER_TEAMS = 'fetchTrainerTeams'
export const ADD_TEAM = 'addTeam'
export const ADD_PLAYER_TO_TEAM = 'addPlayerToTeam'
export const ADD_TRAINER_TO_TEAM = 'addTrainerToTeam'
export const UPDATE_TEAM = 'updateTeam'
export const UPDATE_PLAYER = 'updatePlayer'
export const UPDATE_TRAINER = 'updateTrainer'
export const DELETE_TEAM = 'deleteTeam'
export const ADD_USER_TO_TEAM = 'addUserToTeam'
export const REMOVE_USER_FROM_TEAM = 'removeUserFromTeam'
export const FETCH_TEAM_AVERAGES = 'fetchTeamAverages'

// FOR USERS
export const FETCH_USER = 'fetchUser'
export const FETCH_USER_ROLES = 'fetchUserRoles'
export const FETCH_USER_ROLES_BY_ID = 'fetchUserRolesById'
export const FETCH_USER_AGE_BY_ID = 'fetchUserAgeById'
export const UPDATE_USER_BIRTHDATE = 'updateUserBirthdate'
export const UPDATE_USER = 'updateUser'
export const DELETE_USER = 'deleteUser'
export const FETCH_FIELD_POSITIONS = 'fetchFieldPositions'
export const IMPORT_USERS_TEAM = 'importUsersTeam'
export const IMPORT_USERS_ORG = 'importUsersOrg'
export const GET_USER = 'getUser'

// FOR ABILITIES
export const FETCH_ABILITY_HISTORY = 'fetchAbilityHistory'

// FOR LICENSES
export const FETCH_TEMPLATES = 'fetchTemplates'
export const FETCH_TEMPLATE = 'fetchTemplate'
export const CREATE_TEMPLATE = 'createTemplate'
export const UPDATE_TEMPLATE = 'updateTemplate'
export const DELETE_TEMPLATE = 'deleteTemplate'
export const FETCH_ACTIVE_LICENSE = 'fetchActiveLicense'
export const ASSIGN_LICENSE = 'assignLicense'
export const UPDATE_LICENSE = 'updateLicense'
export const DEACTIVATE_LICENSE = 'deactivateLicense'

// FOR REPORTS
export const FETCH_REPORTS = 'fetchReports'
export const DOWNLOAD_REPORT = 'downloadReport'
export const CREATE_REPORT = 'createReport'
export const DELETE_REPORT = 'deleteReport'
export const FETCH_REPORT_SKILLGAMES = 'fetchReportSkillgames'
export const FETCH_REPORT_DATES = 'fetchReportDates'

// FOR PRINTING CARDS
export const UPDATE_SELECTED_PLAYERS_FOR_PRINT = 'updatePlayersForPrinting'
export const UPDATE_ENABLED_BLEED_DOTS = 'updateEnabledBleedDots'
export const FETCH_IMAGE_WITHOUT_CORS = 'fetchImageWithoutCors'
export const UPDATE_PRINTING_ACTIVE_COLOR = 'updatePrintingActiveColor'
export const UPDATE_PRINTING_MAIN_COLOR = 'updatePrintingMainColor'
export const UPDATE_PRINTING_LOGO = 'updatePrintingLogo'
export const UPDATE_PRINTING_SECONDARY_COLOR = 'updatePrintingSecondaryColor'
export const FETCH_PRINTING_PLAYERS = 'fetchPrintingPlayers'

// FOR PLAYERS
export const UPDATE_SMALL_CARD = 'updateSmallCard'
export const UPDATE_SPIDERWEB_VIEW = 'updateSpiderWebView'

// FOR PLAYER COMPARISON
export const FETCH_EXAMPLE_RESULTS = 'fetchExampleResults'
