import { SET_FIELD_POSITIONS, RESET_USERS, SET_USER } from '../mutations.type'

const actions = {
  [SET_USER](state, payload) {
    state.user = payload
  },

  [SET_FIELD_POSITIONS](state, payload) {
    state.field_positions = payload
  },

  [RESET_USERS](state) {
    state.unassigned = []
    state.users = []
    state.players = []
    state.trainers = []
    state.admins = []
  }
}

export default actions
