export function boostAbility(ability, age) {
  let maxScore = 94
  let boostedScore = ability + getBoostValue(age)
  if (boostedScore > maxScore) boostedScore = maxScore
  return boostedScore
}

export function getBoostValue(age) {
  if (age <= 12) return 45
  switch (age) {
    case 13:
      return 40
    case 14:
      return 35
    case 15:
      return 30
    case 16:
      return 25
    case 17:
      return 20
    case 18:
      return 15
    case 19:
      return 10
    case 20:
      return 5
    default:
      return 0
  }
}
