import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import auth from './auth'
import themes from './custom-themes'
import organisations from './organisations'
import ranking from './ranking'
import skillgames from './skillgames'
import results from './results'
import teams from './teams'
import users from './users'
import abilities from './abilities'
import licenses from './licenses'
import reports from './reports'
import dataPolicies from './data-policies'
import printingCards from './printing-cards'
import players from './players'
import comparePlayers from './player-comparison'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    themes,
    organisations,
    ranking,
    skillgames,
    teams,
    users,
    results,
    abilities,
    licenses,
    reports,
    dataPolicies,
    printingCards,
    players,
    comparePlayers
  }
})
