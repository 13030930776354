import { BOOST_ABILITIES, SET_ABILITY_HISTORY } from '../mutations.type'
import { boostAbility } from '../../services/skillscore.service'

const mutations = {
  [SET_ABILITY_HISTORY](state, history) {
    state.abilities.history = history
  },
  [BOOST_ABILITIES](state, user) {
    user.abilities.speed = boostAbility(user.abilities.speed, user.age)
    user.abilities.dribbling = boostAbility(user.abilities.dribbling, user.age)
    user.abilities.endurance = boostAbility(user.abilities.endurance, user.age)
    user.abilities.passing = boostAbility(user.abilities.passing, user.age)
    user.abilities.agility = boostAbility(user.abilities.agility, user.age)
    user.abilities.reaction = boostAbility(user.abilities.reaction, user.age)
    user.abilities.details.average = boostAbility(
      user.abilities.details.average,
      user.age
    )
  }
}

export default mutations
