import ApiService from '@/services/api.service'
import DataPolicyService from '@/services/data-policy.service'

import {
  getToken,
  saveToken,
  JWT_TOKEN_KEY
} from '@/services/local_storage.service'

import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  FETCH_CUSTOM_THEME,
  REQUEST_RESET_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  FETCH_USER_ROLES,
  CHECK_DATA_POLICIES,
  FETCH_ME
} from '../actions.type'

import { SET_AUTH, PURGE_AUTH } from '../mutations.type'

const actions = {
  async [LOGIN](context, payload) {
    // Fetch user info and save it in store
    const { data } = await ApiService.post('auth/login', payload).catch(
      (error) => {
        return new Promise((resolve, reject) => {
          if (error.response) {
            reject(error.response.data.message)
          }
        })
      }
    )
    context.commit(SET_AUTH, data.user)

    // Save JWT token in local storage
    saveToken(JWT_TOKEN_KEY, data.token)
    await ApiService.setHeader()

    // Fetch user roles (permissions) and save it in store
    await context.dispatch(FETCH_USER_ROLES)
    // Fetch custom theme and update UI with CSS variables
    await context.dispatch(FETCH_CUSTOM_THEME, data.user.organisation_id)
    // Fetch and save the data policies in the state
    await context.dispatch(CHECK_DATA_POLICIES, data.user.id)
  },

  async [CHECK_AUTH](context) {
    if (!getToken(JWT_TOKEN_KEY)) {
      context.commit(PURGE_AUTH)
      return
    }

    if (!context.getters.isAuthenticated) {
      await ApiService.setHeader()

      // Set user
      await context.dispatch(FETCH_ME)

      // Fetch user roles (permissions) and save it in store
      await context.dispatch(FETCH_USER_ROLES)
      // Fetch custom theme and update UI with CSS variables
      await context.dispatch(
        FETCH_CUSTOM_THEME,
        context.getters.user.organisation_id
      )
    }

    // Fetch and save the data policies in the state
    await context.dispatch(CHECK_DATA_POLICIES, context.getters.user.id)
    // Check if user accepted the policies, if not, send to login
    if (
      !DataPolicyService.hasSavedBirthDate() ||
      !DataPolicyService.hasAcceptedPolicies()
    ) {
      context.commit(PURGE_AUTH)
    }
  },

  async [FETCH_ME](context) {
    await ApiService.get('/users/me')
      .then(({ data }) => {
        context.commit(SET_AUTH, data.data)
      })
      .catch(() => {
        context.commit(PURGE_AUTH)
      })
  },

  [LOGOUT](context) {
    context.commit(PURGE_AUTH)
  },

  async [REQUEST_RESET_PASSWORD](context, token) {
    return await ApiService.get(`/password/find/${token}`)
  },

  async [FORGOT_PASSWORD](context, payload) {
    return await ApiService.post('/password/create', payload)
  },

  async [RESET_PASSWORD](context, payload) {
    return await ApiService.post('/password/reset', payload)
  }
}

export default actions
