import { UPDATE_SMALL_CARD } from '../actions.type'
import { UPDATE_SPIDERWEB_VIEW } from '../actions.type'
import { SET_SMALL_CARD } from '../mutations.type'
import { SET_SPIDERWEB_VIEW } from '../mutations.type'

const actions = {
  [UPDATE_SMALL_CARD](context, payload) {
    context.commit(SET_SMALL_CARD, payload)
  },
  [UPDATE_SPIDERWEB_VIEW](context, payload) {
    context.commit(SET_SPIDERWEB_VIEW, payload)
  }
}

export default actions
