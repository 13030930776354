import ApiService from '@/services/api.service'
import {
  ADD_TEMPLATE,
  SET_ACTIVE_LICENSE,
  SET_ERROR,
  SET_TEMPLATE,
  SET_TEMPLATES,
  SET_UPDATED_LICENSE
} from '@/store/mutations.type'
import {
  FETCH_ACTIVE_LICENSE,
  ASSIGN_LICENSE,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  FETCH_TEMPLATE,
  FETCH_TEMPLATES,
  UPDATE_TEMPLATE,
  UPDATE_LICENSE,
  DEACTIVATE_LICENSE
} from '../actions.type'

const actions = {
  async [FETCH_TEMPLATES](context) {
    await ApiService.get('/licenses/templates')
      .then(({ data }) => {
        context.commit(SET_TEMPLATES, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_TEMPLATE](context, templateId) {
    await ApiService.get(`/licenses/templates/${templateId}`)
      .then(({ data }) => {
        context.commit(SET_TEMPLATE, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [CREATE_TEMPLATE](context, payload) {
    await ApiService.post('/licenses/templates', payload)
      .then(({ data }) => {
        context.commit(ADD_TEMPLATE, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [UPDATE_TEMPLATE](context, { templateId, payload }) {
    await ApiService.patch(`/licenses/templates/${templateId}`, payload)
      .then(() => {
        context.dispatch(FETCH_TEMPLATES)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [DELETE_TEMPLATE](context, templateId) {
    await ApiService.delete(`/licenses/templates/${templateId}`)
      .then(() => {
        context.dispatch(FETCH_TEMPLATES)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_ACTIVE_LICENSE](context) {
    const orgId = context.getters.getSelectedOrganisation.id
    await ApiService.get(`/licenses/organisation/${orgId}/active`).then(
      ({ data }) => {
        context.commit(SET_ACTIVE_LICENSE, data)
      }
    )
  },

  async [ASSIGN_LICENSE](context, payload) {
    await ApiService.post('/licenses', payload)
      .then(({ data }) => {
        context.commit(SET_UPDATED_LICENSE, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [UPDATE_LICENSE](context, { licenseId, payload }) {
    await ApiService.patch(`/licenses/${licenseId}`, payload)
      .then(({ data }) => {
        context.commit(SET_UPDATED_LICENSE, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [DEACTIVATE_LICENSE](context, licenseId) {
    await ApiService.patch(`/licenses/${licenseId}/deactivate`)
      .then(({ data }) => {
        context.commit(SET_UPDATED_LICENSE, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  }
}

export default actions
