import ApiService from '@/services/api.service'
import { download } from '@/services/file.service'
import {
  ADD_REPORT,
  REMOVE_REPORT,
  SET_ERROR,
  SET_REPORT_DATES,
  SET_REPORT_SKILLGAMES,
  SET_REPORTS
} from '@/store/mutations.type'
import {
  CREATE_REPORT,
  DELETE_REPORT,
  DOWNLOAD_REPORT,
  FETCH_REPORT_DATES,
  FETCH_REPORT_SKILLGAMES,
  FETCH_REPORTS
} from '@/store/actions.type'

const actions = {
  async [FETCH_REPORTS](context) {
    const teamId = context.rootGetters.getSelectedTeam.id

    await ApiService.get(`reports/teams/${teamId}`)
      .then(({ data }) => {
        context.commit(SET_REPORTS, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [DOWNLOAD_REPORT](context, report) {
    await ApiService.getWithConfig(`reports/${report.id}/download`, {
      responseType: 'blob'
    })
      .then(({ data }) => {
        download(new Blob([data]), `${report.name}.${report.fileType}`)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [CREATE_REPORT](context, { teamId, payload }) {
    await ApiService.post(`reports/teams/${teamId}`, payload)
      .then(({ data }) => {
        context.commit(ADD_REPORT, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [DELETE_REPORT](context, report) {
    await ApiService.delete(`reports/${report.id}`)
      .then(() => {
        context.commit(REMOVE_REPORT, report)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_REPORT_SKILLGAMES](context) {
    const teamId = context.rootGetters.getSelectedTeam.id

    await ApiService.get(`reports/teams/${teamId}/skillgames`)
      .then(({ data }) => {
        context.commit(SET_REPORT_SKILLGAMES, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_REPORT_DATES](context) {
    const teamId = context.rootGetters.getSelectedTeam.id

    await ApiService.get(`reports/teams/${teamId}/dates`)
      .then(({ data }) => {
        context.commit(SET_REPORT_DATES, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  }
}

export default actions
