import ApiService from '@/services/api.service'
import permissions from '@/services/permission.service'

import {
  FETCH_USER,
  UPDATE_USER,
  DELETE_USER,
  FETCH_FIELD_POSITIONS,
  FETCH_ALL_TEAM_USERS,
  FETCH_ALL_ORG_USERS,
  IMPORT_USERS_TEAM,
  IMPORT_USERS_ORG,
  FETCH_AND_STORE_TEAMS_BY_ORG,
  FETCH_USER_ROLES,
  FETCH_USER_ROLES_BY_ID,
  FETCH_USER_AGE_BY_ID,
  UPDATE_USER_BIRTHDATE,
  GET_USER
} from '../actions.type'

import {
  SET_ERROR,
  SET_USER,
  SET_FIELD_POSITIONS,
  SET_ROLES,
  PURGE_AUTH,
  SET_AUTH
} from '../mutations.type'

const actions = {
  async [FETCH_USER](context, userId) {
    await ApiService.get(`/users/${userId}`)
      .then(({ data }) => {
        context.commit(SET_USER, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [GET_USER](context, userId) {
    return await ApiService.get(`/users/${userId}`)
      .then(({ data }) => {
        return data.data
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_USER_ROLES](context) {
    await ApiService.get('/users/me/roles')
      .then(({ data }) => {
        context.commit(SET_ROLES, data)
      })
      .catch(() => {
        context.commit(PURGE_AUTH)
      })
  },

  async [FETCH_USER_ROLES_BY_ID](context, userId) {
    await ApiService.get(`/users/${userId}/roles`)
      .then(({ data }) => {
        context.commit(SET_ROLES, data)
      })
      .catch(() => {
        context.commit(PURGE_AUTH)
      })
  },

  async [FETCH_USER_AGE_BY_ID](context, userId) {
    return await ApiService.get(`/users/${userId}/age`)
      .then(({ data }) => {
        return data.age
      })
      .catch(() => {
        context.commit(PURGE_AUTH)
      })
  },

  async [UPDATE_USER_BIRTHDATE](context, payload) {
    return await ApiService.patch(`/users/${payload.userId}/birthdate`, {
      birth_date: payload.birthdate
    })
      .then(({ data }) => {
        context.commit(SET_AUTH, data)
      })
      .catch(() => {
        context.commit(PURGE_AUTH)
      })
  },

  async [UPDATE_USER](context, payload) {
    await ApiService.patch(`/users/${payload.user_id}`, payload)
      .then(({ data }) => {
        context.commit(SET_AUTH, data.data)
      })
      .catch(({ response }) => {
        const errors = response.data.errors
        context.commit(SET_ERROR, errors[Object.keys(errors)[0]][0])
      })
  },

  async [DELETE_USER](context, payload) {
    /**
     * Delete a user
     */
    await ApiService.delete(`/users/${payload.id}`)
      .then(() => {
        context.dispatch(FETCH_ALL_TEAM_USERS)
        if (permissions.hasOrganizationAdminRoles()) {
          context.dispatch(FETCH_ALL_ORG_USERS)
        }
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_FIELD_POSITIONS](context) {
    /**
     * Fetch all possible values of field positions
     */

    await ApiService.get(`/field-positions`)
      .then(({ data }) => {
        context.commit(SET_FIELD_POSITIONS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [IMPORT_USERS_TEAM](context, payload) {
    /**
     * Save players from a CSV file in to ONE specified team
     */

    const teamId = context.getters.getSelectedTeam.id

    await ApiService.post(`teams/${teamId}/users/players/import`, payload)
      .then(() => {
        context.dispatch(FETCH_ALL_TEAM_USERS)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors[0][0])
      })
  },

  async [IMPORT_USERS_ORG](context, payload) {
    /**
     * Save players from a CSV file in to specified teams
     */

    const orgId = context.getters.getSelectedOrganisation.id

    await ApiService.post(
      `organisations/${orgId}/users/players/import`,
      payload
    )
      .then(() => {
        context.dispatch(FETCH_AND_STORE_TEAMS_BY_ORG)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors[0][0])
      })
  }
}

export default actions
