export default function () {
  return {
    teams: null,
    selectedTeam: {},
    players: null,
    allPlayers: null,
    trainers: null,
    teamAverages: null
  }
}
