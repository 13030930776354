import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Router from 'vue-router'
import axios from 'axios'
import { BootstrapVue } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faArrowUp,
  faArrowDown,
  faFileLines,
  faDownload,
  faPenToSquare,
  faTrash,
  faSearch,
  faCheck,
  faXmark,
  faStar
} from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import permissionServices from '@/services/permission.service'
import dataPolicyServices from '@/services/data-policy.service'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './styles/main.css'

// Setup axios
Vue.prototype.$axios = axios
Vue.prototype.$axios.defaults.baseURL = process.env.VUE_APP_HTTP_URL
Vue.prototype.$devMode = true // devmode
Vue.config.productionTip = false

// Make packages available throughout the project globally
Vue.use(BootstrapVue)
Vue.use(Router)

// Add common functions
Vue.prototype.$permission = permissionServices
Vue.prototype.$dataPolicy = dataPolicyServices

// Add FontAwesome icons
library.add(
  faArrowUp,
  faArrowDown,
  faFileLines,
  faDownload,
  faPenToSquare,
  faTrash,
  faSearch,
  faCheck,
  faXmark,
  faStar,
  faStarRegular
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')
