import ApiService from '@/services/api.service'

import {
  FETCH_COUNTRIES,
  FETCH_COUNTRY_SKILLGAME_RANKING,
  FETCH_PLAYER_BEST_RANKS,
  FETCH_TEAM_BEST_RANKS,
  FETCH_PLAYER_SKILLGAME_RANKING,
  FETCH_POSITIONS,
  FETCH_TEAM_SKILLGAME_RANKING,
  FETCH_TEAM_STENGTH_INDEX_RANKING
} from '../actions.type'
import {
  SET_COUNTRIES,
  SET_COUNTRY_SKILLGAME_RANKING,
  SET_ERROR,
  SET_PLAYER_RANKS,
  SET_PLAYER_SKILLGAME_RANKING,
  SET_POSITIONS,
  SET_TEAM_SKILLGAME_RANKING,
  SET_TEAM_STENGTH_INDEX_RANKING,
  SET_TEAM_BEST_RANKS
} from '../mutations.type'

const actions = {
  async [FETCH_PLAYER_SKILLGAME_RANKING](context, { skillgame_id, filters }) {
    await ApiService.getWithParams(
      `/ranking/skillgame/${skillgame_id}/players`,
      filters
    )
      .then(({ data }) => {
        context.commit(SET_PLAYER_SKILLGAME_RANKING, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_COUNTRY_SKILLGAME_RANKING](context, { skillgame_id, filters }) {
    await ApiService.getWithParams(
      `/ranking/skillgame/${skillgame_id}/countries`,
      filters
    )
      .then(({ data }) => {
        context.commit(SET_COUNTRY_SKILLGAME_RANKING, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_TEAM_SKILLGAME_RANKING](context, { skillgame_id, filters }) {
    await ApiService.getWithParams(
      `/ranking/skillgame/${skillgame_id}/teams`,
      filters
    )
      .then(({ data }) => {
        context.commit(SET_TEAM_SKILLGAME_RANKING, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_TEAM_STENGTH_INDEX_RANKING](context, { filters }) {
    await ApiService.getWithParams(`/ranking/strength-index/teams`, filters)
      .then(({ data }) => {
        context.commit(SET_TEAM_STENGTH_INDEX_RANKING, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_PLAYER_BEST_RANKS](context, userId) {
    await ApiService.get(`/ranking/${userId}/ranks/skill-score-categories`)
      .then(({ data }) => {
        context.commit(SET_PLAYER_RANKS, { userId, data })
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_TEAM_BEST_RANKS](context, { teamId, cache }) {
    await ApiService.get(
      `/ranking/teams/${teamId}/ranks/skill-score?use_cache=${cache}`
    )
      .then(({ data }) => {
        context.commit(SET_TEAM_BEST_RANKS, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_COUNTRIES](context) {
    await ApiService.get('/countries')
      .then(({ data }) => {
        let mapped = data.map((x) => x.location)
        context.commit(SET_COUNTRIES, mapped)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_POSITIONS](context) {
    await ApiService.get('/field-positions')
      .then(({ data }) => {
        context.commit(SET_POSITIONS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  }
}

export default actions
