export default function () {
  return {
    organisations: [],
    liteOrganisations: [],
    selectedOrganisation: null,
    unassigned: [],
    users: [],
    players: [],
    trainers: [],
    admins: []
  }
}
