import Vue from 'vue'
import VueRouter from 'vue-router'
import authGuard from './authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('../layouts/LoginLayout'),
    children: [
      {
        path: '/',
        name: 'login',
        component: () => import('../pages/auth/Login')
      }
    ]
  },
  {
    path: '/activate/:token',
    component: () => import('../layouts/LoginLayout'),
    children: [
      {
        path: '/',
        name: 'activate',
        component: () => import('../pages/auth/ActivateAccount')
      }
    ]
  },
  {
    path: '/password',
    component: () => import('../layouts/LoginLayout'),
    children: [
      {
        path: 'forgot',
        name: 'password-forgot',
        component: () => import('../pages/auth/PasswordForgot')
      },
      {
        path: ':token',
        name: 'password-reset',
        component: () => import('../pages/auth/PasswordReset')
      }
    ]
  },
  {
    path: '/before-enter',
    component: () => import('../layouts/LoginLayout'),
    children: [
      {
        path: '/birth-date/save',
        name: 'save-birth-date',
        component: () => import('../pages/auth/SaveBirthDate')
      },
      {
        path: '/policies/accept',
        name: 'accept-policies',
        component: () => import('../pages/auth/AcceptDataPolicies')
      }
    ]
  },
  {
    path: '/',
    name: 'dashboard-root',
    component: () => import('../layouts/DashboardLayout'),
    redirect: { name: 'home' },
    meta: { breadcrumb: 'Home' },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../pages/Dashboard')
      },
      {
        path: 'overview',
        name: 'team-overview',
        component: () => import('../pages/general/TeamPage'),
        meta: { breadcrumb: 'Team' }
      },
      {
        path: 'profile',
        name: 'player-overview',
        component: () => import('../pages/general/ProfilePage'),
        meta: { breadcrumb: 'Profile' }
      },
      {
        path: 'skillgame/:skillgame',
        name: 'player-skillgame',
        component: () => import('../pages/player/PlayerSkillGamePage'),
        meta: { breadcrumb: 'Skillgame' }
      },
      {
        path: 'category/:category',
        name: 'player-category',
        component: () => import('../pages/player/PlayerCategoryPage'),
        meta: { breadcrumb: 'Test Library' }
      },
      {
        path: 'data',
        name: 'data',
        component: { render: (h) => h('router-view') },
        redirect: { name: 'data-players' },
        meta: { breadcrumb: 'Data' },
        children: [
          {
            path: 'teams',
            name: 'data-teams',
            component: () => import('../pages/data/TeamResultsPage'),
            meta: { breadcrumb: 'Teams' }
          },

          {
            path: 'comparison',
            name: 'player-comparison',
            component: () => import('../pages/data/PlayerComparisonPage'),
            meta: { breadcrumb: 'Player comparison' }
          },
          {
            path: 'players',
            name: 'data-players-wrapper',
            component: { render: (h) => h('router-view') },
            meta: { breadcrumb: 'Players' },
            redirect: { name: 'data-players' },
            children: [
              {
                path: 'overview',
                name: 'data-players',
                component: () => import('../pages/data/TeamPlayersPage')
              },

              {
                path: ':id',
                name: 'data-player-wrapper',
                component: { render: (h) => h('router-view') },
                meta: { breadcrumb: 'Player' },
                redirect: { name: 'data-player' },
                children: [
                  {
                    path: 'profile',
                    name: 'data-player',
                    component: () => import('../pages/general/ProfilePage')
                  },
                  {
                    path: 'skillgame/:skillgame',
                    name: 'data-player-skillgame',
                    component: () =>
                      import('../pages/player/PlayerSkillGamePage'),
                    meta: { breadcrumb: 'Skillgame' }
                  },
                  {
                    path: 'category/:category',
                    name: 'data-player-category',
                    component: () =>
                      import('../pages/player/PlayerCategoryPage'),
                    meta: { breadcrumb: 'Test Library' }
                  }
                ]
              }
            ]
          },
          {
            path: 'live-scoreboard',
            name: 'live-scoreboard',
            component: () => import('../pages/data/LiveScoreboardPage'),
            meta: { breadcrumb: 'Live scoreboard' }
          },
          // {
          //   path: 'report',
          //   name: 'report',
          //   component: () => import('../pages/data/Report'),
          //   meta: { breadcrumb: 'Report' }
          // },
          {
            path: 'reports',
            name: 'reports',
            component: () => import('../pages/data/Reports'),
            meta: { breadcrumb: 'Reports' }
          }
        ]
      },
      {
        path: 'management',
        name: 'management',
        component: { render: (h) => h('router-view') },
        redirect: { name: 'management-users' },
        meta: { breadcrumb: 'Management' },
        children: [
          {
            path: 'users',
            name: 'management-users',
            component: () => import('../pages/management/ManagementUsersPage'),
            meta: { breadcrumb: 'Users' }
          },
          {
            path: 'organisations',
            name: 'management-organisations',
            component: () =>
              import('../pages/management/ManagementOrganisationsPage'),
            meta: { breadcrumb: 'Organisations' }
          },
          {
            path: 'teams',
            name: 'management-teams',
            component: () => import('../pages/management/ManagementTeamsPage'),
            meta: { breadcrumb: 'Teams' }
          },
          {
            path: 'skillgames',
            name: 'management-skillgames',
            component: () =>
              import('../pages/management/ManagementSkillgamesPage'),
            meta: { breadcrumb: 'Skillgames' }
          },
          {
            path: 'players',
            name: 'management-players',
            component: () =>
              import('../pages/management/ManagementPlayersPage'),
            meta: { breadcrumb: 'Players' }
          },
          {
            path: 'trainers',
            name: 'management-trainers',
            component: () =>
              import('../pages/management/ManagementTrainersPage'),
            meta: { breadcrumb: 'Trainers' }
          },
          {
            path: 'licenses',
            name: 'management-licenses',
            component: () =>
              import('../pages/management/ManagementLicensesPage'),
            meta: { breadcrumb: 'Licenses' }
          },
          {
            path: 'print-cards',
            name: 'print-cards',
            component: () => import('../pages/data/PrintCardsPage'),
            meta: { breadcrumb: 'Print cards' }
          }
        ]
      },
      {
        name: 'ranking',
        path: 'ranking',
        component: { render: (h) => h('router-view') },
        redirect: { name: 'ranking-players' },
        meta: { breadcrumb: 'Ranking' },
        children: [
          {
            name: 'ranking-players',
            path: 'players',
            component: () => import('../pages/ranking/PlayerSkillgameRanking'),
            meta: { breadcrumb: 'Players' }
          }
          // {
          //   name: 'ranking-countries',
          //   path: 'countries',
          //   component: () => import('../pages/ranking/CountrySkillgameRanking'),
          //   meta: { breadcrumb: 'Countries' }
          // },
          // {
          //   name: 'ranking-teams',
          //   path: 'teams',
          //   component: () => import('../pages/ranking/TeamRanking'),
          //   meta: { breadcrumb: 'Teams' }
          // }
        ]
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/SettingsPage'),
        meta: { breadcrumb: 'Settings' }
      }
    ]
  },
  {
    path: '*',
    component: () => import('../layouts/LoginLayout'),
    children: [
      {
        path: '*',
        name: 'not-found',
        component: () => import('../pages/NotFound')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// route middlewares
authGuard(router)

export default router
