import {
  SET_ORGANISATIONS,
  SET_LITE_ORGANISATIONS,
  SET_SELECTED_ORGANISATION,
  SET_ORG_UNASSIGNED,
  SET_ORG_USERS,
  SET_ORG_PLAYERS,
  SET_ORG_TRAINERS,
  SET_ORG_ADMINS,
  PURGE_ORGANISATIONS,
  SET_UPDATED_LICENSE,
  ADD_NEW_ORGANISATION,
  SET_UPDATED_ORGANISATION,
  REMOVE_ORGANISATION,
  ADD_USER_TO_UNASSIGNED,
  REMOVE_USER_FROM_UNASSIGNED,
  REMOVE_FROM_ORG_PLAYERS,
  ADD_TO_ORG_PLAYERS
} from '../mutations.type'

const actions = {
  [SET_ORGANISATIONS](state, payload) {
    state.organisations = payload
  },
  [SET_LITE_ORGANISATIONS](state, payload) {
    state.liteOrganisations = payload
  },

  [SET_SELECTED_ORGANISATION](state, orgId) {
    state.selectedOrganisation = state.liteOrganisations.find(
      (o) => o.id === orgId
    )
  },

  [SET_ORG_UNASSIGNED](state, unassigned) {
    state.unassigned = unassigned
  },

  [SET_ORG_USERS](state, users) {
    state.users = users
  },

  [SET_ORG_PLAYERS](state, players) {
    state.players = players
  },

  [SET_ORG_TRAINERS](state, trainers) {
    state.trainers = trainers
  },

  [SET_ORG_ADMINS](state, admins) {
    state.admins = admins.map((v) => ({ ...v, is_org_admin: true }))
  },

  [PURGE_ORGANISATIONS](state) {
    state.organisations = []
    state.selectedOrganisation = null
    state.unassigned = []
    state.users = []
    state.players = []
    state.trainers = []
    state.admins = []
  },

  [SET_UPDATED_LICENSE](state, license) {
    state.organisations.find(
      (org) => org.id === license.organisationId
    ).license = license
  },

  [ADD_NEW_ORGANISATION](state, organisation) {
    state.organisations.unshift(organisation)
  },

  [SET_UPDATED_ORGANISATION](state, organisation) {
    const index = state.organisations.findIndex(
      (org) => org.id === organisation.id
    )
    state.organisations.splice(index, 1, organisation)
  },

  [REMOVE_ORGANISATION](state, organisation) {
    state.organisations = state.organisations.filter(
      (org) => org.id !== organisation.id
    )
  },

  [ADD_USER_TO_UNASSIGNED](state, user) {
    state.unassigned.push(user)
  },

  [REMOVE_USER_FROM_UNASSIGNED](state, user) {
    state.unassigned = state.unassigned.filter((u) => u.id !== user.id)
  },

  [ADD_TO_ORG_PLAYERS](state, user) {
    state.players.push(user)
  },

  [REMOVE_FROM_ORG_PLAYERS](state, userId) {
    state.players = state.players.filter((u) => u.id !== userId)
  }
}

export default actions
