export default function () {
  return {
    selectedPlayersForPrinting: [],
    enabledBleedDots: false,
    imageWithoutCors: null,
    printing_active_color: '#f7941e',
    printing_main_color: '#e0e0ff',
    printing_secondary_color: '#f7941e',
    printing_logo_image: null,
    printingPlayers: null
  }
}
