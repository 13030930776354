import { SET_SKILLGAME, SET_SKILLGAMES } from '../mutations.type'

const actions = {
  [SET_SKILLGAME](state, skillgame) {
    state.skillgame = skillgame
  },
  [SET_SKILLGAMES](state, skillgames) {
    state.skillgames = skillgames
  }
}

export default actions
