import { SET_ERROR, ERROR_CHECKED } from '../mutations.type'

const mutations = {
  /** Adds new error to the state **/
  [SET_ERROR](state, error) {
    state.error = error
  },

  /** Adds new error to the state **/
  [ERROR_CHECKED](state) {
    state.error = null
  }
}

export default mutations
