const getters = {
  getOrganisations: (state) => state.organisations,
  getOrganisationsLite: (state) => state.liteOrganisations,
  getSelectedOrganisation: (state) => state.selectedOrganisation,
  getOrgUnassigned: (state) => state.unassigned,
  getOrgUsers: (state) => state.users,
  getOrgPlayers: (state) => state.players,
  getOrgTrainers: (state) => state.trainers,
  getOrgAdmins: (state) => state.admins
}
export default getters
