<template>
  <div id="app" :style="customTheme">
    <router-view />
    <ModalRoot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModalBus } from '@/shared/eventBus'
import ModalRoot from '@/components/modals/ModalBus/ModalRoot'
import TestErrorModal from '@/components/modals/TestErrorModal'

export default {
  name: 'App',
  components: {
    ModalRoot
  },
  created() {
    this.$store.watch(
      (state, getters) => getters.getError,
      (oldValue) => {
        if (oldValue) {
          ModalBus.$emit('open', {
            component: TestErrorModal,
            title: 'Error',
            props: { error: oldValue },
            closeOnClick: false
          })
        }
      }
    )
  },
  computed: {
    ...mapGetters([
      'theme',
      'getPrintingActiveColor',
      'getPrintingMainColor',
      'getPrintingSecondaryColor'
    ]),
    customTheme() {
      return {
        '--custom-theme-main': this.theme.main_color,
        '--custom-theme-secondary': this.theme.secondary_color,
        '--custom-theme-active': this.theme.active_color,
        '--custom-theme-default-text': this.theme.default_text_color,
        '--custom-theme-secondary-text': this.theme.secondary_text_color,
        '--custom-printing-active': this.getPrintingActiveColor,
        '--custom-printing-main': this.getPrintingMainColor,
        '--custom-printing-secondary': this.getPrintingSecondaryColor
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald');
#app {
  position: absolute;
  height: 100%;
  width: 100%;
}

html,
body {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;

  position: absolute;
  width: 100%;
  height: 100%;
  /*text-align: center;*/
  background-color: var(--skillgames-darker) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
