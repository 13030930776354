import {
  FETCH_CUSTOM_THEME,
  CHANGE_CUSTOM_THEME,
  RESET_CUSTOM_THEME
} from '../actions.type'
import {
  SET_CUSTOM_THEME,
  SET_PRINTING_ACTIVE_COLOR,
  SET_PRINTING_MAIN_COLOR,
  SET_PRINTING_LOGO_IMAGE,
  SET_ERROR,
  SET_PRINTING_SECONDARY_COLOR
} from '../mutations.type'
import ApiService from '@/services/api.service'

const actions = {
  /** Fetches the color theme for the provided organisation **/
  async [FETCH_CUSTOM_THEME](context, orgId) {
    await ApiService.get(`/custom-themes/${orgId}`)
      .then(({ data }) => {
        context.commit(SET_CUSTOM_THEME, data.data)
        context.commit(SET_PRINTING_ACTIVE_COLOR, data.data.active_color)
        context.commit(SET_PRINTING_MAIN_COLOR, data.data.main_color)
        context.commit(SET_PRINTING_SECONDARY_COLOR, data.data.secondary_color)
        context.commit(SET_PRINTING_LOGO_IMAGE, data.data.logo_path)
      })
      .catch((e) => {
        context.commit(SET_ERROR, e)
      })
  },

  async [CHANGE_CUSTOM_THEME](context, payload) {
    const orgId = context.getters.getSelectedOrganisation.id

    await ApiService.post(`/custom-themes/${orgId}`, payload)
      .then(({ data }) => {
        context.commit(SET_CUSTOM_THEME, data)

        if (payload.active_color) {
          context.commit(SET_PRINTING_ACTIVE_COLOR, payload.active_color)
        }
        if (payload.main_color) {
          context.commit(SET_PRINTING_MAIN_COLOR, payload.main_color)
        }
        if (payload.secondary_color) {
          context.commit(SET_PRINTING_SECONDARY_COLOR, payload.secondary_color)
        }
        if (payload.logo_path) {
          context.commit(SET_PRINTING_LOGO_IMAGE, payload.logo_path)
        }
      })
      .catch((e) => {
        context.commit(SET_ERROR, e)
      })
  },

  async [RESET_CUSTOM_THEME](context) {
    const orgId = context.getters.getSelectedOrganisation.id

    await ApiService.patch(`/custom-themes/${orgId}/reset`)
      .then(({ data }) => {
        context.commit(SET_CUSTOM_THEME, data)
        context.commit(SET_PRINTING_ACTIVE_COLOR, data.active_color)
        context.commit(SET_PRINTING_MAIN_COLOR, data.main_color)
        context.commit(SET_PRINTING_SECONDARY_COLOR, data.secondary_color)
        context.commit(SET_PRINTING_LOGO_IMAGE, data.logo_path)
      })
      .catch((e) => {
        context.commit(SET_ERROR, e)
      })
  }
}

export default actions
