import store from '../store'

export default {
  hasAdminRoles() {
    return store.getters.roles.is_admin
  },

  hasOrganizationAdminRoles() {
    return this.hasAdminRoles() || store.getters.roles.is_org_admin
  },

  hasTrainerRoles() {
    return this.hasOrganizationAdminRoles() || store.getters.roles.is_trainer
  },

  hasPlayerRoles() {
    return this.hasTrainerRoles || store.getters.roles.is_player
  },

  canCreateCustomTheme() {
    return (
      store.getters.getSelectedOrganisation?.license?.canCreateCustomTheme ||
      this.hasAdminRoles()
    )
  },

  canDownloadReports() {
    return (
      store.getters.getSelectedOrganisation?.license?.canDownloadReports ||
      this.hasAdminRoles()
    )
  },

  canPrintCards() {
    return (
      store.getters.getSelectedOrganisation?.license?.canPrintCards ||
      this.hasAdminRoles()
    )
  },

  canSendNewsletters() {
    return (
      store.getters.getSelectedOrganisation?.license?.canSendNewsletters ||
      this.hasAdminRoles()
    )
  },

  canViewLiveScoreboard() {
    return (
      store.getters.getSelectedOrganisation?.license?.canViewLiveScoreboard ||
      this.hasAdminRoles()
    )
  },
  canComparePlayers() {
    return (
      store.getters.getSelectedOrganisation?.license?.canComparePlayers ||
      this.hasAdminRoles()
    )
  },

  canAddTeams() {
    const max = store.getters.getSelectedOrganisation?.license?.maxTeams
    const current = store.getters.getTeams?.length
    return current < max || this.hasAdminRoles()
  },

  canAddPlayers() {
    const max = store.getters.getSelectedOrganisation?.license?.maxPlayers
    const current = store.getters.getOrgPlayers?.length
    return current < max || this.hasAdminRoles()
  }
}
