import { SET_SELECTED_PLAYERS_FOR_PRINT } from '../mutations.type'
import { SET_ENABLED_BLEED_DOTS } from '../mutations.type'
import { SET_IMAGE_WITHOUT_CORS } from '../mutations.type'
import { SET_PRINTING_ACTIVE_COLOR } from '../mutations.type'
import { SET_PRINTING_MAIN_COLOR } from '../mutations.type'
import { SET_PRINTING_LOGO_IMAGE } from '../mutations.type'
import { SET_PRINTING_SECONDARY_COLOR } from '../mutations.type'
import { SET_PRINTING_PLAYERS } from '../mutations.type'

const mutations = {
  [SET_SELECTED_PLAYERS_FOR_PRINT](state, players) {
    state.selectedPlayersForPrinting = players
  },
  [SET_ENABLED_BLEED_DOTS](state, enabled) {
    state.enabledBleedDots = enabled
  },
  [SET_IMAGE_WITHOUT_CORS](state, image) {
    state.imageWithoutCors = image
  },
  [SET_PRINTING_ACTIVE_COLOR](state, color) {
    state.printing_active_color = color
  },
  [SET_PRINTING_MAIN_COLOR](state, color) {
    state.printing_main_color = color
  },
  [SET_PRINTING_SECONDARY_COLOR](state, color) {
    state.printing_secondary_color = color
  },
  [SET_PRINTING_LOGO_IMAGE](state, image) {
    state.printing_logo_image = image
  },
  [SET_PRINTING_PLAYERS](state, players) {
    state.printingPlayers = players
  }
}

export default mutations
