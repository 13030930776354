import {
  SET_COUNTRIES,
  SET_COUNTRY_SKILLGAME_RANKING,
  SET_PLAYER_RANKS,
  SET_PLAYER_SKILLGAME_RANKING,
  SET_POSITIONS,
  SET_TEAM_SKILLGAME_RANKING,
  SET_TEAM_STENGTH_INDEX_RANKING,
  SET_TEAM_BEST_RANKS
} from '../mutations.type'

const actions = {
  [SET_PLAYER_SKILLGAME_RANKING](state, ranking) {
    state.ranking.player.skillgame = ranking
  },
  [SET_COUNTRY_SKILLGAME_RANKING](state, ranking) {
    state.ranking.country.skillgame = ranking
  },
  [SET_TEAM_SKILLGAME_RANKING](state, ranking) {
    state.ranking.team.skillgame = ranking
  },
  [SET_TEAM_STENGTH_INDEX_RANKING](state, ranking) {
    state.ranking.team.strengthIndex = ranking
  },
  [SET_PLAYER_RANKS](state, { userId, data }) {
    state.ranking.player.bestRanks = {
      ...state.ranking.player.bestRanks,
      [userId]: data
    }
  },
  [SET_TEAM_BEST_RANKS](state, data) {
    state.ranking.team.bestRanks = data
  },

  [SET_COUNTRIES](state, countries) {
    state.filters.countries = countries
  },
  [SET_POSITIONS](state, positions) {
    state.filters.positions = positions
  }
}

export default actions
