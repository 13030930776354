import {
  ACCEPT_DATA_POLICY,
  CHECK_DATA_POLICIES,
  FETCH_DATA_POLICIES,
  WITHDRAW_DATA_POLICY
} from '../actions.type'
import {
  PURGE_AUTH,
  SET_DATA_POLICIES,
  SET_ERROR,
  SET_USER_DATA_POLICIES
} from '../mutations.type'

import ApiService from '@/services/api.service'
import router from '../../router'

const actions = {
  async [FETCH_DATA_POLICIES](context) {
    await ApiService.get('/data-policies/latest')
      .then(({ data }) => {
        context.commit(SET_DATA_POLICIES, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [CHECK_DATA_POLICIES](context, userId) {
    const response = await ApiService.get(
      `/data-policies/check/${userId}`
    ).catch(({ response }) => {
      context.commit(SET_ERROR, response.data.message)
    })

    context.commit(SET_USER_DATA_POLICIES, response.data)
  },

  async [ACCEPT_DATA_POLICY](context, policyId) {
    await ApiService.post(`/data-policies/${policyId}/accept`)
      .then(async ({ data }) => {
        await context.commit(SET_USER_DATA_POLICIES, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [WITHDRAW_DATA_POLICY](context, policyId) {
    await ApiService.post(`/data-policies/${policyId}/withdraw`)
      .then(() => {
        context.commit(PURGE_AUTH)
        router.push({ path: '/login' })
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  }
}

export default actions
