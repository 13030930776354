import {
  SET_TEAMS,
  SET_SELECTED_TEAM,
  RESET_TEAMS,
  SET_TEAM_PLAYERS,
  SET_ALL_TEAM_PLAYERS,
  SET_TEAM_TRAINERS,
  PURGE_TEAMS,
  ADD_NEW_TEAM,
  SET_UPDATED_TEAM,
  REMOVE_TEAM,
  ADD_TO_PLAYERS,
  ADD_TO_TRAINERS,
  REMOVE_FROM_TEAM_PLAYERS,
  REMOVE_FROM_TRAINERS,
  ADD_NEW_PLAYER_TO_TEAM,
  ADD_NEW_TRAINER_TO_TEAM,
  SET_UPDATED_PLAYER,
  SET_UPDATED_TRAINER,
  SET_TEAM_AVERAGES
} from '../mutations.type'

const mutations = {
  [SET_TEAMS](state, teams) {
    state.teams = teams
  },

  [SET_SELECTED_TEAM](state, teamId) {
    state.selectedTeam = state.teams.find((t) => t.id === teamId)
  },

  [RESET_TEAMS](state) {
    state.teams = []
  },

  [SET_TEAM_PLAYERS](state, players) {
    state.players = players
  },

  [SET_ALL_TEAM_PLAYERS](state, players) {
    state.allPlayers = players
  },

  [SET_TEAM_TRAINERS](state, trainers) {
    state.trainers = trainers
  },

  [PURGE_TEAMS](state) {
    state.teams = null
    state.selectedTeam = {}
    state.players = null
    state.trainers = null
  },

  [ADD_NEW_TEAM](state, team) {
    state.teams.unshift(team)
  },

  [SET_UPDATED_TEAM](state, team) {
    const index = state.teams.findIndex((t) => t.id === team.id)
    state.teams.splice(index, 1, team)
  },

  [REMOVE_TEAM](state, team) {
    state.teams = state.teams.filter((t) => t.id !== team.id)
  },

  [ADD_NEW_PLAYER_TO_TEAM](state, user) {
    state.players.unshift(user)
  },

  [ADD_NEW_TRAINER_TO_TEAM](state, user) {
    state.trainers.unshift(user)
  },

  [ADD_TO_PLAYERS](state, user) {
    state.players.push(user)
  },

  [SET_UPDATED_PLAYER](state, user) {
    const index = state.players.findIndex((u) => u.id === user.id)
    state.players.splice(index, 1, user)
  },

  [REMOVE_FROM_TEAM_PLAYERS](state, userId) {
    state.players = state.players.filter((u) => u.id !== userId)
  },

  [ADD_TO_TRAINERS](state, user) {
    state.trainers.push(user)
  },

  [SET_UPDATED_TRAINER](state, user) {
    const index = state.trainers.findIndex((u) => u.id === user.id)
    state.trainers.splice(index, 1, user)
  },

  [REMOVE_FROM_TRAINERS](state, user) {
    state.trainers = state.trainers.filter((u) => u.id !== user.id)
  },
  [SET_TEAM_AVERAGES](state, averages) {
    state.teamAverages = averages
  }
}

export default mutations
