import store from '../store'

export default {
  hasAcceptedPolicies() {
    return this.hasAcceptedPersonalData() && this.hasAcceptedHealthData()
  },

  hasAcceptedPersonalData() {
    return store.getters.getUserDataPolicies.has_accepted_personal_data
  },

  hasAcceptedHealthData() {
    return store.getters.getUserDataPolicies.has_accepted_health_data
  },

  hasUserAcceptedPolicies(user) {
    return (
      this.hasUserAcceptedPersonalData(user) &&
      this.hasUserAcceptedHealthData(user)
    )
  },

  hasUserAcceptedPersonalData(user) {
    return user.policies?.has_accepted_personal_data
  },

  hasUserAcceptedHealthData(user) {
    return user.policies?.has_accepted_health_data
  },

  hasSavedBirthDate() {
    return store.getters.user.birth_date !== 'N/A'
  }
}
