import { FETCH_ABILITY_HISTORY } from '../actions.type'
import ApiService from '@/services/api.service'
import { SET_ABILITY_HISTORY, SET_ERROR } from '@/store/mutations.type'

const actions = {
  async [FETCH_ABILITY_HISTORY](context, userId) {
    return ApiService.get(`/users/${userId}/ability-history`)
      .then(({ data }) => {
        context.commit(SET_ABILITY_HISTORY, data.data)

        return data.data
      })
      .catch((e) => {
        context.commit(SET_ERROR, e)
      })
  }
}
export default actions
