import {
  SET_BEST_RESULTS,
  SET_PLAYER_SKILLGAME_RESULTS,
  SET_PLAYER_ALL_RESULTS
} from '../mutations.type'

const actions = {
  [SET_PLAYER_SKILLGAME_RESULTS](state, results) {
    state.results.playerSkillgame = results
  },
  [SET_BEST_RESULTS](state, results) {
    state.results.best = results
  },
  [SET_PLAYER_ALL_RESULTS](state, results) {
    state.results.playerAllResults = results
  }
}

export default actions
