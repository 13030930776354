const getters = {
  getTeams: (state) => state.teams,
  getSelectedTeam: (state) => state.selectedTeam,
  getTeamPlayers: (state) => state.players,
  getAllTeamPlayers: (state) => state.allPlayers,
  getTeamTrainers: (state) => state.trainers,
  getTeamAverages: (state) => state.teamAverages
}

export default getters
