<template>
  <div>
    <div style="white-space: pre-wrap">
      {{ error }}
    </div>

    <div class="modal-footer mt-3">
      <button class="skillgames-button--action" @click="errorChecked">
        Ok
      </button>
    </div>
  </div>
</template>

<script>
import { ERROR_CHECKED } from '@/store/mutations.type'

export default {
  name: 'TestErrorModal',
  props: ['error'],
  methods: {
    errorChecked: function () {
      this.$store.commit(ERROR_CHECKED)
      this.$emit('onClose')
    }
  }
}
</script>
