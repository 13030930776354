import { destroyToken, JWT_TOKEN_KEY } from '@/services/local_storage.service'

import {
  SET_AUTH,
  SET_ROLES,
  PURGE_AUTH,
  PURGE_TEAMS,
  PURGE_ORGANISATIONS
} from '../mutations.type'

const mutations = {
  [SET_AUTH](state, user) {
    state.isAuthenticated = true
    state.user = user
  },

  [SET_ROLES](state, roles) {
    state.roles = roles
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    this.commit(PURGE_TEAMS, null)
    this.commit(PURGE_ORGANISATIONS, null)
    destroyToken(JWT_TOKEN_KEY)
  }
}

export default mutations
