const getters = {
  playerSkillgameRanking: (state) => state.ranking.player.skillgame,
  countrySkillgameRanking: (state) => state.ranking.country.skillgame,
  teamSkillgameRanking: (state) => state.ranking.team.skillgame,
  teamStrengthIndexRanking: (state) => state.ranking.team.strengthIndex,
  playerRanks: (state) => state.ranking.player.bestRanks,
  teamRanks: (state) => state.ranking.team.bestRanks,
  countries: (state) => state.filters.countries,
  positions: (state) => state.filters.positions,
  timeSpans: (state) => state.filters.timeSpans
}

export default getters
