import {
  ADD_TEMPLATE,
  SET_ACTIVE_LICENSE,
  SET_TEMPLATE,
  SET_TEMPLATES
} from '../mutations.type'

const mutations = {
  [SET_TEMPLATES](state, templates) {
    state.templates = templates
  },

  [SET_TEMPLATE](state, template) {
    state.template = template
  },

  [ADD_TEMPLATE](state, template) {
    state.templates.push(template)
  },

  [SET_ACTIVE_LICENSE](state, license) {
    state.activeLicense = license
  }
}

export default mutations
