import { SET_DATA_POLICIES, SET_USER_DATA_POLICIES } from '../mutations.type'

const mutations = {
  /** Sets the fetched data policies **/
  [SET_DATA_POLICIES](state, policies) {
    state.dataPolicies = policies
  },

  /** Sets the updated data policies for the authenticated user **/
  [SET_USER_DATA_POLICIES](state, policies) {
    state.userDataPolicies = policies
  }
}

export default mutations
