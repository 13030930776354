import ApiService from '@/services/api.service'
import { FETCH_EXAMPLE_RESULTS } from '../actions.type'
import { SET_EXAMPLE_RESULTS, SET_ERROR } from '../mutations.type'

const actions = {
  async [FETCH_EXAMPLE_RESULTS](context, payload) {
    await ApiService.get(
      `/users/${payload.firstPlayerId}/${payload.secondPlayerId}/results/example`
    )
      .then(({ data }) => {
        context.commit(SET_EXAMPLE_RESULTS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  }
}

export default actions
