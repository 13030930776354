import { SET_FIRST_COMPARISON_PLAYER } from '../mutations.type'
import { SET_SECOND_COMPARISON_PLAYER } from '../mutations.type'
import { SET_EXAMPLE_RESULTS } from '../mutations.type'

const mutations = {
  [SET_FIRST_COMPARISON_PLAYER](state, player) {
    state.firstComparisonPlayer = player
  },
  [SET_SECOND_COMPARISON_PLAYER](state, player) {
    state.secondComparisonPlayer = player
  },
  [SET_EXAMPLE_RESULTS](state, results) {
    state.exampleResults = results
  }
}

export default mutations
