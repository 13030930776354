import ApiService from '@/services/api.service'

import {
  FETCH_BEST_RESULTS,
  FETCH_PLAYER_SKILLGAME_RESULTS,
  FETCH_RESULTS,
  FETCH_ALL_RESULTS
} from '../actions.type'
import {
  SET_BEST_RESULTS,
  SET_ERROR,
  SET_PLAYER_SKILLGAME_RESULTS,
  SET_PLAYER_ALL_RESULTS
} from '../mutations.type'
const actions = {
  async [FETCH_RESULTS](context, filters) {
    return await ApiService.getWithParams('/results', filters)
      .then(({ data }) => {
        return data.data
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_ALL_RESULTS](context, userId) {
    return await ApiService.getWithParams(`/users/${userId}/results`)
      .then(({ data }) => {
        context.commit(SET_PLAYER_ALL_RESULTS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_PLAYER_SKILLGAME_RESULTS](context, { userId, filters }) {
    await ApiService.getWithParams(`/users/${userId}/results`, filters)
      .then(({ data }) => {
        context.commit(SET_PLAYER_SKILLGAME_RESULTS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_BEST_RESULTS](context, userId) {
    await ApiService.get(`/users/${userId}/results/best`)
      .then(({ data }) => {
        context.commit(SET_BEST_RESULTS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  }
}

export default actions
