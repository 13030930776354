import { SET_SMALL_CARD } from '../mutations.type'
import { SET_SPIDERWEB_VIEW } from '../mutations.type'

const mutations = {
  [SET_SMALL_CARD](state, smallCard) {
    state.smallCard = smallCard
  },
  [SET_SPIDERWEB_VIEW](state, spiderWebView) {
    state.spiderWebView = spiderWebView
  }
}

export default mutations
