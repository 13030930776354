export default function () {
  return {
    ranking: {
      player: {
        skillgame: {},
        bestRanks: []
      },
      team: {
        skillgame: {},
        strengthIndex: {},
        bestRanks: []
      },
      country: {
        skillgame: []
      }
    },
    filters: {
      countries: [],
      positions: [],
      timeSpans: [
        { text: 'Today', value: 'day' },
        { text: 'This week', value: 'week' },
        { text: 'This month', value: 'month' },
        { text: 'This year', value: 'year' }
      ]
    }
  }
}
