import ApiService from '@/services/api.service'

import {
  FETCH_SKILLGAME,
  FETCH_SKILLGAMES,
  FETCH_TEAM_SKILLGAMES
} from '../actions.type'
import { SET_ERROR, SET_SKILLGAME, SET_SKILLGAMES } from '../mutations.type'

const actions = {
  async [FETCH_SKILLGAME](context, skillgame) {
    await ApiService.get(`/skillgames/${skillgame}`)
      .then(({ data }) => {
        context.commit(SET_SKILLGAME, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_SKILLGAMES](context) {
    await ApiService.get('/skillgames')
      .then(({ data }) => {
        context.commit(SET_SKILLGAMES, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_TEAM_SKILLGAMES](context, teamId) {
    await ApiService.get(`/teams/${teamId}/skillgames`)
      .then(({ data }) => {
        context.commit(SET_SKILLGAMES, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  }
}

export default actions
